import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

const MappingFiles = props => (
  <Layout>
    <Helmet>
      <title>Mapping Files</title>
      <meta name="description" content="Generic Page" />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>Mapping Files</h1>
          </header>
          <div className="box">
          <p>Mixxx Mapping File: <a href="https://mixrider.com/downloads/mixxx-rider.midi.xml">mixxx-rider.midi.xml</a></p>
          </div>
          <div className="box">
          <p>Mixxx Script File: <a href="https://mixrider.com/downloads/mixxx-rider-scripts.js">mixxx-rider-scripts.js</a></p>
          </div>
        </div>
      </section>
    </div>
  </Layout>
)

export default MappingFiles
